@charset "utf-8"
@import "fonts"
@import "colors"
@import "bulma"

.is-sticky-top
  position: sticky
  top: 0
  z-index: 1000

.is-table-header
  background-color: $valmet-green-light !important